<template>
    <button :class="[size,styleButton]">{{value}}</button>
</template>

<script>
    export default {
        name: "StandartButton",
        props:{
            value: String,
            styleButton: String,
            size: String
        }
    }
</script>

<style scoped lang="scss">
    button{
        width: 335px;
        height: 46px;

        &:hover{
            filter: brightness(94%);
        }
    }

    .standart-button{
        background-image: linear-gradient(42deg, #E83F94 0%, #F54E5E 100%);
        height: 48px;
        font-family: Roboto, "sans-serif";
        font-size: 16px;
        letter-spacing: 0.5px;
        text-align: center;
        border: 0;
        outline: 0;
        color: white;
        border-radius: 26.5px;
    }

    .white-button{
        height: 46px;
        font-family: Roboto, "sans-serif";
        font-size: 17px;
        letter-spacing: 0px;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
        border: 0;
        outline: 0;
        color: $pink-button-color;
        border-radius: 8px;
    }
</style>